<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">정산정보</strong>
      </div>
      <div class="pos_right">
        <span class="txt_info">통화 코드 : KRW</span>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <!-- admin 타입 type_admin -->
        <caption class="ir_caption">
          정산정보 표
        </caption>
        <colgroup>
          <col style="width: 192px" />
          <col />
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">정산상태</th>
            <td colspan="3">
              <!-- 기본 -->
              <div v-if="!isEditMode" class="row_pack">
                <span class="txt_view" style="width: 267px">{{
                  draftData.accountYn ? "완료" : "미완료"
                }}</span>
                <!-- 수정가능한 상태일때 수정 버튼 노출 -->
                <button
                  v-if="isCompleteStep && !isView"
                  class="btn_small btn_fourthly"
                  @click="isEditMode = true"
                >
                  수정
                </button>
              </div>

              <!-- 수정상태 -->
              <div v-else class="row_pack">
                <Radio
                  :dataList="CALCULATE_STATE_RADIO_LIST"
                  name="radioPrice"
                  :selectedId.sync="accountYn"
                />
                <p class="desc_tip tc_red">
                  <span class="ico_account ico_exclam_r" />완료를 선택하면 더이상 정산을 할 수
                  없습니다.
                </p>
                <button class="btn_small btn_tertiary" @click="onChangeAccountYnStatus">
                  적용
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">청구기간</th>
            <td>{{ draftData.accountStartDate }} ~ {{ draftData.accountEndDate }}</td>
            <th scope="row">청구유형</th>
            <td>
              {{ draftData.accountType === accountType.REGULARLY ? "정기" : "1회 또는 수시" }}
            </td>
          </tr>
          <tr>
            <th scope="row">월별 청구 금액</th>
            <td colspan="3">
              <ul class="list_monthly">
                <li v-for="item in draftData.purchasePaymentList" :key="item.paymentMonth">
                  <span class="txt_monthly">{{ item.paymentMonth }}</span>
                  <span class="txt_price">{{ item.amount | currency }}</span>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Radio from "@/_approval/components/common/radio/Radio";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { accountType } from "@/_approval/const/const";

export default {
  name: "DraftPurchasePayment",
  components: { Radio },
  mixins: [CommFlatpickrMixin],
  props: {
    isCompleteStep: Boolean,
    draftData: Object,
    isView: Boolean,
  },
  data() {
    return {
      isEditMode: false,
      accountType: accountType,
      accountYn: "",
      CALCULATE_STATE_RADIO_LIST: [
        { code: "N", name: "미완료", id: "accountN" },
        { code: "Y", name: "완료", id: "accountY" },
      ],
    };
  },
  watch: {
    draftData(newVal, oldVal) {
      if (!newVal || newVal === oldVal) return;
      this.setAccountYn();
    },
  },
  mounted() {
    this.setAccountYn();
  },
  methods: {
    setAccountYn() {
      this.accountYn = this.draftData.accountYn ? "Y" : "N";
    },
    onChangeAccountYnStatus() {
      this.isEditMode = false;
      this.$emit("onChangeAccountYnStatus", this.accountYn);
    },
  },
};
</script>
